import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <ToastContainer closeButton={true} pauseOnHover={false} position={'top-right'}
                        pauseOnFocusLoss={false}></ToastContainer>
        <App/>
    </>
);
